const deviceTypeListEnum = [
    {
      id: "1",
      name: "血压计"
    },
    {
      id:"2",
      name: "体温贴"
    },
    {
      id: "3",
      name: "血糖仪"
    }
  ];


  export {
    deviceTypeListEnum
  }