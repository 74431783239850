import axios from "axios";
const context = "/deviceservice";

/***
 * 首页接口
 */
export function getIndexPageData(data:any){
  return axios.get(`${context}/home/index`)
}

/**
 * 获取12个月的短信消耗量
 */
export function getPhoneMsgStatistics(data:any){
  return axios.get(`/servicesupport/alimenssage/month/statistics`, data);
}

/**
 * 新增设备
 */
export function addDevice(data: any) {
  return axios.post(`${context}/mallDevice`, data);
}

/**
 * excel 批量增加设备
 * */
export function batchAddDevice(data: any) {
  return axios.post(`${context}/mallDevice`, data);
}

/**
 * 修改设备
 * */
export function updateDevice(data: any) {
  return axios.put(`${context}/mallDevice`, data);
}

/**
 * 批量删除设备
 */
export function removeDevice(data: any) {
  return axios.post(`${context}/mallDevice/batchRemove`, data);
}

/***
 * 设备解绑
 */
export function unbindDevice( data:any ) {
  return axios.post(`${context}/mallDevice/batchUnbind`, data);
}

/***
 * 设备绑定
 */
export function bindDevice( data:any ){
  return axios.post(`${context}/mallDevice/batchBind`, data);
}


/**
 * 批量修改
 */
export function batchUpdateDevice(data: any) {
  return axios.put(`${context}/mallDevice/batchConfig`, data);
}

/***
 * 获取设备分页列表
 */
export function getDevicePagination(data: any) {
  return axios.get(`${context}/mallDevice/pagination`, { params: data });
}


/***
 * 获取设备类型列表
 */
export function getDeviceTypePagination( data:any ){
  return axios.get(`${context}/deviceSpu/pagination`, {params: data})
}

/***
 * 新增设备类型
 */
export function addDeviceType( data:any ){
  return axios.post(`${context}/deviceSpu`,  data);
}

/***
 * 修改设备类型
 */
export function updateDeviceType( data:any ){
  return axios.put(`${context}/deviceSpu`, data);
}

/***
 * 删除设备类型
 */
export function removeDeviceType( data:any ){
  return axios.post(`${context}/deviceSpu/remove`, data);
}


/**
 *  获取 SPU 评论列表
 * */ 
export function getDeviceCommentPagination( data:any ){
  return axios.get(`${context}/deviceComments/pagination`, { params: data })
}

/***
 * 删除 SPU 评论列表
 */
export function removeDeviceComment( data:any ){
  return axios.post(`${context}/deviceComments/remove`, data)
}

/**
 * 审核评论
 */
export function auditDeviceComment( data:any ){
  return axios.put(`${context}/deviceComments/audit`, data);
}



/*********** 体温贴设备 ******************************************** */
/***
 * 1、获取体温贴设备分页列表
 */
export function getTwtDevicePagination( data:any ){
  return axios.get(`${context}/api/devices/pagination`, {params:data});
}

/***
 * 2、新增体温贴
 */
export function addTwtDevice( data:any ){
  return axios.post(`${context}/api/devices/add`, data);
}

/***
 * 修改设备名称或者mac地址
 */
export function updateTwtDevice( data:any ){
  return axios.put(`${context}/api/devices/update`, data);
}
 
/***
 * 3、移除体温贴
 */
export function removeTwtDevice( data:any ){
  return axios.post( `${context}/api/devices/remove`, data)
}


/***
 * 4、绑定体温贴
 */
export function bindTwtDevice( data:any ){
  return axios.put(`${context}/api/devices/bind`,data)
}


/***
 * 5、解绑体温贴
 */
export function unbindTwtDevice( data:any ){
  return axios.put(`${context}/api/devices/unbind`,data);
}