import { reactive } from "vue";

let permissionList:any = localStorage.getItem("PERMISSION_LIST") || [];
if( permissionList && permissionList.length ){
    permissionList = JSON.parse(permissionList);
}
export function useRolePermission(){
    return reactive({
            addRolePerm: !!permissionList.find( function(item:any){ return item.perms=='users:addrole:btn'} ),
            editRolePerm: !!permissionList.find( function(item:any){ return item.perms=='users:editrole:btn'} ),
            removeRolePerm: !!permissionList.find( function(item:any){ return item.perms=='users:removerole:btn'} ),
        })
}

export function useUserPermission(){
    return reactive({
        addUserPerm: !!permissionList.find( function(item:any){ return  item.perms=='users:adduser:btn' } ),
        editUserPerm: !!permissionList.find( function(item:any){ return  item.perms=='users:edituser:btn' }),
        removeUserPerm: !!permissionList.find( function(item:any){ return  item.perms=='users:removeuser:btn' } ),
        detailUserPerm: !!permissionList.find( function(item:any){ return  item.perms=='users:detailuser:btn'} ),
    })
}

export function useDevicePermission(){
    return reactive({
        addDevicePerm: !!permissionList.find( function(item:any){ return  item.perms=='devices:adddevice:btn' } ),
        batchImportDevicePerm: !!permissionList.find( function(item:any){ return  item.perms=='devices:batchimport:btn' } ),
        batchSettingDevicePerm: !!permissionList.find( function(item:any){ return  item.perms=='devices:batchsetting:btn' } ),
        batchUnbindDevicePerm: !!permissionList.find( function(item:any){ return  item.perms=='devices:batchunbind:btn'}  ),
        unbindDevicePerm: !!permissionList.find( function(item:any){ return  item.perms=='devices:unbind:btn' } ),
        detailDevicePerm: !!permissionList.find( function(item:any){ return  item.perms=='devices:detail:btn'}  ),
        editDevicePerm: !!permissionList.find( function(item:any){ return  item.perms=='devices:editdevice:btn' } ),
        removeDevicePerm: !!permissionList.find( function(item:any){ return  item.perms=='devices:removedevice:btn' } ),
    })
}

export function useOrderPermission(){
    return reactive({
        removeOrderPerm: !!permissionList.find( function(item:any){ return  item.perms=='orders:removeorder:btn' } ),
    })
}
